<template>
	<div class="hy-main">
		<!-- <login-header :loginTitle="''"></login-header> -->
		<div class="frameCon" style="background-color: #FFFFFF; padding: 20px 0;margin-top: 10px;">
			<div class="buyerHeader">
				<h3 class="buyerHeaderTit">
					<el-breadcrumb separator="/">
						<!-- <el-breadcrumb-item :to="{ name: 'UserAuthentication' }"> -->
							<!-- 认证列表 -->
							<!-- {{ $t('userCenter.enterpriseAuth.Certification_list') }} -->
						<!-- </el-breadcrumb-item> -->
						<el-breadcrumb-item>{{$t('i18nn_85e8e84f3e8ab46b')}}</el-breadcrumb-item>
					</el-breadcrumb>
				</h3>
			</div>
			<div class="formMsgCon">
				<div class="formMsgDet">
					<p>{{$t('i18nn_10986afa1c3bc094')}}<!-- 所有信息均必填，请严格按照营业执照上的信息填写，保证数据信息准确性！ -->
					</p>
				</div>
			</div>
			<div style="margin: 10px 20px; width: 900px; " v-loading="loading_det" :element-loading-text="$t('tips.loading')">
				<div v-loading="loading" :element-loading-text="$t('tips.Submitting')">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
						<div class="buyerDataHeader4"><h3>{{$t('i18nn_896ba18e1a7c9a07')}}</h3></div>
						<!-- <el-row> -->
						<!-- <el-col :span="12"> -->
						<el-form-item :label="$t('i18nn_1df68c34677c2aae')" prop="companyName"><el-input v-model="ruleForm.companyName" :placeholder="$t('FormMsg.Please_Enter')"></el-input></el-form-item>
						<!-- </el-col> -->
						<!-- <el-col :span="12"> -->
						<el-form-item :label="$t('i18nn_00b8608bd21b2160')" prop="introducer"><el-input v-model="ruleForm.introducer" :placeholder="$t('FormMsg.Please_Enter')"></el-input></el-form-item>
						<!-- </el-col> -->
						<!-- </el-row> -->

						<div class="buyerDataHeader4"><h3>{{$t('i18nn_ed00b7cc66d3c1c0')}}</h3></div>
						<el-table :data="ruleForm.cusContactArr" :border="true" style="width: 900px">
							<!-- <el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column> -->

							<el-table-column prop="name" :label="$t('i18nn_ea4756bc1642e0f1')" width="150" align="right">
								<!-- <template slot-scope="scope">
		              <div>
		
		              </div>
		            </template> -->
							</el-table-column>

							<el-table-column prop="value" :label="$t('i18nn_8e2be23dd3b81a31')">
								<template slot-scope="scope">
									<div><el-input v-model="scope.row.value" :placeholder="$t('FormMsg.Please_Enter')"></el-input></div>
								</template>
							</el-table-column>
						</el-table>
						<!-- <el-row> -->
						<!-- <el-col :span="12"> -->
						<!-- <el-form-item :label="$t('i18nn_4cf3d3e9cdca9259')" prop=""><el-input v-model="ruleForm.address" :placeholder="$t('FormMsg.Please_Enter')"></el-input></el-form-item> -->
						<!-- </el-col> -->
						<!-- <el-col :span="12"> -->
						<!-- <el-form-item :label="$t('i18nn_8292010ff4193c50')" prop="businessMobile">
		              <el-input v-model="ruleForm.businessMobile" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
		            </el-form-item> -->
						<!-- <el-form-item label="邮箱(email)" prop=""><el-input v-model="ruleForm.email" :placeholder="$t('FormMsg.Please_Enter')"></el-input></el-form-item> -->
						<!-- </el-col> -->
						<!-- </el-row> -->

						<!-- <div class="buyerDataHeader4"><h3>{{$t('i18nn_032abee95f72da18')}}</h3></div> -->
						<!-- <el-row> -->
						<!-- <el-col :span="12"> -->
						<!-- <el-form-item :label="$t('Storage.tableColumn.remark')" prop="other"><el-input v-model="ruleForm.other" :placeholder="$t('FormMsg.Please_Enter')"></el-input></el-form-item> -->
						<!-- </el-col> -->
						<!-- <el-col :span="12"> -->
						<!-- <el-form-item :label="$t('i18nn_8292010ff4193c50')" prop="businessMobile">
		              <el-input v-model="ruleForm.businessMobile" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
		            </el-form-item> -->
						<!-- <el-form-item label="邮箱(email)" prop="email">
		              <el-input v-model="ruleForm.email" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
		            </el-form-item> -->
						<!-- </el-col> -->
						<!-- </el-row> -->
						<!-- <div class="buyerDataHeader4">
		          <h3>{{$t('i18nn_2a2e2fbcc567efc6')}}</h3>
		        </div>
		        <div class="formMsgCon">
		          <div class="formMsgDet">
		            <p>1.营业执照：未过期并加盖公司有色公章、有色合同章；</p>
		            <p>2.申请许可证或一般户证明，须显示对应银行的支行信息，并附有银行公章；</p>
		            <p>3.法人/经营者（个体户）身份证正反面照片需清晰明了。</p>
		          </div>
		        </div>
		        <div>
		          <el-form-item :label="$t('i18nn_4442771eba7574b1')" prop="enclosureLicense">
		            <hyUpLoadImg ref="hyUpLoadImg1" :imgId="userInfo.id" :imgwidth="400" :imgheight="250" :imgKey="'enclosureLicense'" :bucket="'huyi-oss-finance'" v-on:changeImgUrl="changeImgUrl1"></hyUpLoadImg>
		          </el-form-item>
		          <el-form-item :label="$t('i18nn_9f904564c255cde6')" prop="openAccount">
		            <hyUpLoadImg ref="hyUpLoadImg2" :imgId="userInfo.id" :imgwidth="400" :imgheight="250" :imgKey="'openAccount'" :bucket="'huyi-oss-finance'" v-on:changeImgUrl="changeImgUrl2"></hyUpLoadImg>
		          </el-form-item>
		          <el-form-item :label="$t('i18nn_0b3f536c7bea3feb')" prop="idCard1">
		            <hyUpLoadImg ref="hyUpLoadImg3" :imgId="userInfo.id" :imgwidth="400" :imgheight="250" :imgKey="'idCard1'" :bucket="'huyi-oss-finance'" v-on:changeImgUrl="changeImgUrl3"></hyUpLoadImg>
		          </el-form-item>
		          <el-form-item :label="$t('i18nn_9436668b05dbd114')" prop="idCard2">
		            <hyUpLoadImg ref="hyUpLoadImg4" :imgId="userInfo.id" :imgwidth="400" :imgheight="250" :imgKey="'idCard2'" :bucket="'huyi-oss-finance'" v-on:changeImgUrl="changeImgUrl4"></hyUpLoadImg>
		          </el-form-item>
		        </div> -->
					</el-form>
					<div style="text-align: center; margin-top: 20px;">
						<el-button type="primary" plain icon="el-icon-back" style="width: 150px;" @click="$router.replace({ name: 'login' })">{{$t('i18nn_d25a9b29d0bb390b')}}</el-button>
						<el-button type="primary" style="width: 300px;" @click="submitForm('ruleForm')">{{$t('i18nn_62c64c2d0f877b17')}}</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// import loginHeader from './Login/loginHeader.vue';
import { mapState, mapMutations } from 'vuex';
export default {
	// name: 'EnterpriseAuthentication',
	//meta信息seo用
	// metaInfo() {
	//   return {
	//     title: this.$t("meta.main")
	//   };
	// },
	components: {
		// loginHeader
		// loginFooter
	},
	data() {
		return {
			// isOpenAccount: false,
			userInfo: this.$store.getters.getUserInfo,
			loading: false,
			loading_det: false,
			loading_code: false,

			selectOption: {
				// bankIdList: []
			},
	
			ruleForm: {
				id: '',
				// cusContactArr: null, //[{"name":"QQ","value":"4528654"}],
				cusContactArr: [{ name: '联系地址', value: '' }, { name: '电子邮箱(email)', value: '' }, { name: '备注', value: '' }],
				companyName: '', //"公司名称",
				introducer: '' //"介绍人"

			},

			rules: {
				// 基本信息
				companyName: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				introducer: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }]

			}
			
		};
	},
	// computed: {
	//   ...mapState({
	//     UserInfo: state => state.login.userInfo
	//   })
	// },
	//创建时
	created() {},
	//编译挂载前
	mounted() {
		console.log('mounted', this.$store);
		
		this.getAuthDet();
		
	},
	methods: {
		...mapMutations(['UPDATE_WH_AUTH']),
		//初始化form表单
		initForm(data) {
			
			this.ruleForm = Object.assign({}, data);

			if (this.ruleForm.cusContact) {
				try {
					this.ruleForm.cusContactArr = JSON.parse(this.ruleForm.cusContact);
				} catch (err) {
					console.log(err);
					this.ruleForm.cusContactArr = [{ name: '联系地址', value: '' }, { name: '电子邮箱(email)', value: '' }, { name: '备注', value: '' }];
				}
			} else {
				this.ruleForm.cusContactArr = [{ name: '联系地址', value: '' }, { name: '电子邮箱(email)', value: '' }, { name: '备注', value: '' }];
			}

		},

		//    认证提交
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					
					let postData = Object.assign({}, this.ruleForm);
					
					// if (!!postData.id && '' != postData.id) {
					// 	this.AuthenticationAction(this.$urlConfig.WhMyCustomerUpdate, postData);
					// } else {
						this.AuthenticationAction(this.$urlConfig.WhMyCustomerUpdate, postData);
					// }
				} else {
					console.log('error submit!!');
					this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: '确定',
					});
					return false;
				}
			});
		},

		//申请
		AuthenticationAction(url, postData) {
			
			console.log('申请请求');
			this.loading = true;

			this.$http
				.put(url, postData)
				.then(({ data }) => {
					console.log('申请成功');
					console.log(data);
					// this.$vux.loading.hide();
					this.loading = false;

					if ('200' == data.code) {
						this.$alert('申请成功,去互易仓~', this.$t('tips.tipsTitle'), {
							type: 'success',
							//confirmButtonText: '确定',
							callback: action => {
								this.getWhAuthDet();
							}
						});
					} else {
						if (!data.msg) {
							data.msg = '申请失败，请重试';
						}
						this.$alert(data.msg, this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: '确定',
						});
					}
				})
				.catch(error => {
					console.log(error);
					this.loading = false;
					this.$alert('申请请求失败，请重试"！', this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: '确定',
					});
				});
		},

		//申请成功,去互易仓~查询仓库申请详情,并赋值
		getWhAuthDet() {
			console.log('查询认证详情请求');
			this.loading = true;
			this.$http
				.get(this.$urlConfig.WhMyCustomerInfo, {
					
				})
				.then(({ data }) => {
					console.log('查询认证详情成功');
					console.log(data);
					// this.$vux.loading.hide();
					this.loading = false;

					if (200 == data.code && data.data && '1' == data.data.isWh) {
						// if('1' == data.data.isWh){
							this.UPDATE_WH_AUTH('1');
							console.log('this.$store.getters.getUserInfo.isWh', this.$store.getters.getUserInfo.isWh);
							if ('1' == this.$store.getters.getUserInfo.isWh) {
								this.$router.replace({ name: this.$store.state.homePage });
							} else {
								//二次赋值，第一次赋值失败的时候
								this.UPDATE_WH_AUTH('1');
								if ('1' == this.$store.getters.getUserInfo.isWh) {
									this.$router.replace({ name: this.$store.state.homePage });
								} else {
									
									this.$message.success('请重新登录~');
									this.$router.replace({ name: 'login' });
								}
							}
						// }
					}
				})
				.catch(error => {
					console.log(error);
					this.loading = false;
					// this.$message.warning('查询认证详情请求失败，请重试！');
				});
		},

		//查询申请详情
		getAuthDet() {
			console.log('查询认证详情请求');
			this.loading_det = true;
			this.$http
				.get(this.$urlConfig.WhMyCustomerInfo, {
					
				})
				.then(({ data }) => {
					console.log('查询认证详情成功');
					console.log(data);
					this.loading_det = false;
					if (200 == data.code && data.data) {
						// if (data.data) {
							
							if('1' == data.data.isWh){
								//
								this.initForm(data.data);
								this.$router.replace({ name: this.$store.state.homePage });
							}
						// }
					} else {
						this.$message.warning(!data.msg ? this.$t('tips.queryErrorFailed') : data.msg);
					}
				})
				.catch(error => {
					console.log(error);
					this.loading_det = false;
					this.$message.warning(this.$t('tips.queryRequestFailed'));
				});
		}
		
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
